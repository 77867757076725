/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import { SocialIcon } from 'react-social-icons';
// import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';


export function FooterB() {
  return (
    <div className="container">
      <footer className="py-3" id="footer">
        {/* <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">

          <SocialIcon url="https://linkedin.com/in/jaketrent" bgColor="#49734F" />

          </li>
          <li className="nav-item">
          <SocialIcon url="https://instagram.com/in/jaketrent" bgColor="#49734F" />
          </li>
          <li className="nav-item">
          <SocialIcon url="https://email.com/in/jaketrent" bgColor="#49734F" />
          </li>
          <li className="nav-item">
          <SocialIcon href="https://www.jovianarchive.com/" url="https://sharethis.com/in/jaketrent" bgColor="#49734F" />
          </li>
        </ul> */}
        <div className='text-center p-4'> © 2023 Isaura Carvalho <br></br>
         <a id="developed" className='text-reset fw-bold' href='https://www.linkedin.com/in/davidcortez-webdev/'>
          developed by: @davidvcortez
         </a>
      </div>
      </footer>
    </div>
  )
}















    // <MDBFooter bgColor='white' className='text-center text-lg-start text-muted'>
    //   <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
    //     <div className='me-5 d-none d-lg-block'>

    //     </div>

    //     <div>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="facebook-f" />
    //       </a>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="twitter" />
    //       </a>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="google" />
    //       </a>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="instagram" />
    //       </a>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="linkedin" />
    //       </a>
    //       <a href='' className='me-4 text-reset'>
    //         <MDBIcon fab icon="github" />
    //       </a>
    //     </div>
    //   </section>

    //   <section className=''>
    //     <MDBContainer className='text-center text-md-start mt-5'>
    //       <MDBRow className='mt-3'>
    //         <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
    //           <h6 className='text-uppercase fw-bold mb-4'>
    //             <MDBIcon icon="gem" className="me-0" />
    //             Isaura Carvalho
    //           </h6>
    //           <p>
    //             Redescobre-te, realinha-te, ousa ser quem és e a vida não te resistirá.
    //           </p>
    //         </MDBCol>

            // {/* <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'> */}
            // {/* <h6 className='text-uppercase fw-bold mb-4'>Consultas</h6>
            //   <p>
            //     <a href='#!' className='text-reset'>
            //       Human Design
            //     </a>
            //   </p> */}
            //   {/* <p> */}
            //   {/* <a href='#!' className='text-reset'>
            //       React
            //     </a>
            //   </p>
            //   <p>
            //     <a href='#!' className='text-reset'>
            //       Vue
            //     </a>
            //   </p>
            //   <p>
            //     <a href='#!' className='text-reset'>
            //       Laravel
            //     </a>
            //   </p> */}
            // {/* </MDBCol> */}

            // <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
            //   <h6 className='text-uppercase fw-bold mb-4'>Links</h6>
            //   <p>
            //     <a href='https://www.instagram.com/isaura__carvalho/' className='text-reset'>
            //       Instagram
            //     </a>
            //   </p>
            //   <p>
            //     <a href='https://www.linkedin.com/in/isaura-carvalho-59568452/' className='text-reset'>
            //       LinkedIn
            //     </a>
            //   </p>
            //   <p>
            //     <a href='https://www.phds.pt/' className='text-reset'>
            //       PHDS - Portugal Human Design School
            //     </a>
            //   </p>
            //   <p>
            //     <a href='https://www.jovianarchive.com/' className='text-reset'>
            //       Jovian Archive
            //     </a>
            //   </p>
            // </MDBCol>

            // {/* <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
            //   <h6 className='text-uppercase fw-bold mb-4'>Contactos</h6>

            //   <p>
            //     <MDBIcon icon="envelope" className="me-1" />
            //     hdisauracarvalho@gmail.com
            //   </p>
            //   <p>
            //     <MDBIcon icon="phone" className="me-1" /> + 351 966 222 251
            //   </p>
            //   <p>
            //     <MDBIcon icon="print" className="me-1" /> +258 843 928 679
            //   </p>
            // </MDBCol> */}
    //       </MDBRow>
    //     </MDBContainer>
    //   </section>

    //   <div className='text-center p-4' style={{ backgroundColor: '#49734F' }}> © 2023 Copyrights:
    //     <a className='text-reset fw-bold' href='https://www.linkedin.com/in/davidcortez-webdev/'>
    //       &nbsp;@davidvcortez
    //     </a>
    //   </div>
    // </MDBFooter>
