const logotext = 'JOHN';
const meta = {
  title: 'John Doe',
  description:
    'I’m John Doe data scientist _ Full stack devloper,currently working in Berlin',
};

const introdata = {
  title: 'I’m John Doe',
  animated: {
    first: 'I love coding',
    second: 'I code cool websites',
    third: 'I develop mobile apps',
  },
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum',
  your_img_url: 'https://images.unsplash.com/photo-1514790193030-c89d266d5a9d',
};

const dataabout = {
  title: 'abit about my self',
  aboutme:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.',
};
const worktimeline = [
  {
    jobtitle: 'Designer of week',
    where: 'YAdfi',
    date: '2020',
  },
  {
    jobtitle: 'Designer of week',
    where: 'Jamalya',
    date: '2019',
  },
  {
    jobtitle: 'Designer of week',
    where: 'ALquds',
    date: '2019',
  },
];



const services = [
  {
    title: 'UI & UX Design',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.',
  },
  {
    title: 'Mobile Apps',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.',
  },
  {
    title: 'Wordpress Design',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.',
  },
];

const contactConfig = {
  YOUR_EMAIL: 'hdisauracarvalho@gmail.com',
  YOUR_FONE: '(+351) 966 222 251',
  YOUR_FONE2: '(+258) 843 928 679',
  description:
    'Congratulo-te por te disponibilizares a entrar nesta viagem de autodescoberta consciente.',
  YOUR_SERVICE_ID: 'service_id',
  YOUR_TEMPLATE_ID: 'template_id',
  YOUR_USER_ID: 'user_id',
};


// const socialLinks = {

// }

export {
  meta,
  dataabout,
  worktimeline,
  services,
  introdata,
  contactConfig,
  logotext,
};
