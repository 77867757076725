// import logo from './logo.svg';
import React from 'react';
import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NavBar2 }  from './components/NavBar2.js';
// import { Banner } from './components/Banner.js';
import { ContactUs } from './components/ContactUs.js';
import { PictureBanner } from './components/PictureBanner';
import { FooterB } from './components/FooterB';
// import { HumanDesign } from './components/HumanDesign';
// import { BannerRight } from './components/BannerRight';
// import { BannerLeft } from './components/BannerLeft';
import { About } from "./components/About";
import { About2 } from "./components/About2";
import { MiddleBanner } from './components/MiddleBanner';
import { CardArticles } from './components/CardArticles';
import { StrictMode } from 'react';
import { ScrollToTop } from './components/ScrollToTop';

// import { Card } from "./components/Card";




function App() {



  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <title>Isaura Carvalho</title>
          <link rel="canonical" href="https://www.isauracarvalho.com/" />
          <meta name="description" content="Human Design Consultas" />
        </Helmet>
      </HelmetProvider>


          <ScrollToTop />
          <StrictMode>
            <NavBar2 />
          </StrictMode>
          <PictureBanner />
          <About />
          {/* <BannerLeft /> */}
          <MiddleBanner />
          <About2 />
          <CardArticles />
          <ContactUs />
          <FooterB />


    </div>
  );
}

export default App;
