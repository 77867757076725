
import React from "react"
import { Container } from "react-bootstrap";
import { contactConfig } from "./Content_option";
import { ImLink } from "react-icons/im";
import { FiLinkedin } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import img404 from "../assets/img/pagenotfound.jpg"


export function Page404() {
  return (


    <div className="contact-div" >
    <img alt="404" src={img404} style={
      {
        width: 1000,
      }
    } />
        {/* <Row className="mb-5 mt-3">
          <h1 className="display-4 mb-4">Contact Me</h1>
          <hr className="t_border my-4 ml-0 text-left" />
        </Row> */}
        <Container id="contact" >
          <div className="left-side-contact">
          </div>
          {/* <Row className="sec_sp"> */}
          <div lg="5" className="mb-5" id="smaller-div">
            <h3 className="color_sec py-4"><strong>Vamos entrar em contacto?</strong></h3>
            <address>
              <strong>Email:</strong>{" "}
              <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
                {contactConfig.YOUR_EMAIL}
              </a>
              <br />
              <br />
              {contactConfig.hasOwnProperty("YOUR_FONE") ? (
                <><p>
                  <strong>Phone:</strong> {contactConfig.YOUR_FONE} <br></br>  <p id="disclaimer">(chamada da rede móvel nacional)</p>
                </p><>
                    <ul className="nav justify-content-center border-bottom pb-3 mb-3" id="linksList">
                      <li className="nav-item">
                        <a href="https://www.linkedin.com/in/isaura-carvalho-59568452/" ><FiLinkedin /></a>
                      </li>
                      <li className="nav-item">
                        <a href="https://www.instagram.com/isaura__carvalho/?next=%2F" ><FaInstagram /></a>
                      </li>
                      <li className="nav-item">
                        <a href="https://www.phds.pt/" ><FaHandHoldingHeart /></a>
                      </li>
                      <li className="nav-item">
                        <a href="https://www.jovianarchive.com/"><ImLink /></a>
                      </li>
                    </ul>
                  </></>
              ) : (
                ""
              )}
            </address>
            {/* <h6>{contactConfig.description}</h6> */}
          </div>
          {/* </Row> */}
        </Container>
      </div>
      )
}
