import React from 'react';
import { NavBar3 } from '../components/NavBar3';
import { Link } from "react-router-dom";

import "../assets/img/css/aboutPage.css";
import { FooterB } from '../components/FooterB';
import { ScrollToTop } from '../components/ScrollToTop';


function About() {
  return (

    <div>
    <ScrollToTop  />
      <NavBar3 />
      <div className="banner2">
        <div className="container">
          {/* <h1>Le Wagon brings <strong>tech skills</strong> to <strong>creative people</strong>!</h1>
          <p>Change your life and learn to code at one of our campuses around the world.</p> */}
        </div>
      </div>
      <h2 className="text-center text-black my-5">Iguais, Diferentes ou Únicos?</h2>
      {/* <h6 className="text-center text-black my-5">Sub Titulo do Artigo</h6> */}
      <p className="artigo1p" >
        Nunca o mundo foi tão abundante em fórmulas de tudo e para todos. Receitas que ensinam passo a passo como ser milionário, como ser feliz, como se comportar, como se alimentar, como ter sucesso, enfim! Um sem-fim de “como” se deve viver, ser ou fazer.
        Há uns anos atrás, eu estaria certa do sucesso universal dessas fórmulas. Hoje sei que a resposta é não ou, pelo menos, não sempre, não para todos.
        E agora algumas reflexões: servirão as mesmas fórmulas para a toda a gente? Todas as pessoas se identificarão com elas?
        Enquanto sociedade, temos vindo a assumir implicitamente duas condições: a primeira, é que os métodos “normalizados” de como fazer isto ou aquilo são universalmente válidos e a segunda, que todos funcionamos do mesmo modo.
        A comprovar esse facto temos a educação homogeneizada, que é o método que ainda predomina na maioria da humanidade, baseada em padrões e fórmulas com as quais aprendemos a enquadrar-nos em modelos convencionais que nos afastam progressivamente da nossa natureza, criando a maior de todas as desigualdades. Como nos ensina Aristóteles, a pior forma de desigualdade é tentar tornar iguais duas coisas diferentes.
        E se, durante o caminho, alguém ousa ser e fazer diferente, a falta de referências para sustentar a sua decisão, a falta de sinalização de para onde seguir caminho, a pressão e o estigma social condicionam, em muitos casos, a pessoa a recuar e voltar ao chamado “normal”.
        Depois, quando chegamos à idade adulta, sentimo-nos frequentemente perdidos, sem saber onde ou a quem recorrer, acomodando-nos por vezes a pessoas ou situações conhecidas, por medo de sair da suposta zona de conforto e de enfrentar o desconhecido, sem sabermos que com isso, podemos perder oportunidades de experienciar algo que poderia ajudar a mudar-nos ou a mudar as nossas vidas.
        E isso leva-me a mais uma questão: afinal somos iguais ou diferentes?
        Se pensarmos numa perspetiva abrangente, somos ambas as coisas. Senão vejamos: A nossa “igualdade” passa por exemplo pela biologia, que nos agrupa na mesma espécie; pela ética, que nos atribui os mesmos direitos e deveres na sociedade; ou pela cosmologia, que nos mostra que somos regidos pelas mesmas leis do universo. Quanto à nossa “diferença”, essa é habitualmente conotada com questões de ordem racial, cultural ou religiosa.
        Muito do que nos iguala ou diferencia e que tem vindo a ser discutido ao longo dos anos, tem sido particularmente direcionado para a área dos direitos humanos. A este propósito, lembro-me de uma frase que se tornou num dos slogans mais usados por tintas, telas e vozes: “todos diferentes, todos iguais”. Mas o inquestionável valor dos movimentos gerados a partir desse conceito revolucionário, foi construído tendo por base os seres humanos enquanto espécie, povo, nação ou cultura.
        No entanto, aquilo a que me refiro hoje é algo substancialmente diferente. É sobre particularidades e subtilezas, aquilo que em cada ser lhe dá a sua individualidade, independentemente da raça, cultura ou religião a que pertença. É sobre o que é ser verdadeiramente único.
        Se olharmos à nossa volta, observamos o quão diferentes somos. Rostos, corpos, e movimentos singulares tornam cada Ser inigualável e irrepetível.
        E se aprofundarmos um pouco, percebemos que essa originalidade já é cientificamente comprovada. Os cientistas afirmam que apenas cerca de 0.5% do DNA é único em cada ser humano. No entanto, essa diferença, aparentemente insignificante, é suficiente para que cada código genético defina características particulares como a impressão digital, a forma do movimento, o tom de voz, o olfato, entre muitas outras.
        Concordamos então que genéticas únicas correspondem a diferentes potenciais a desenvolver e, por consequência, modos particulares de o fazer, tendo em conta a mecânica e o modo diferenciado como cada pessoa funciona.
        Mas o mais interessante é que tudo isto se passa a um nível subtil. É uma energia que sentimos, mas que não consciencializamos. E por isso, não raramente, lhe fugimos, a ignoramos ou a expressamos desadequadamente, simplesmente porque não sabemos como fazer de outro modo.
        Até conhecer o Human Design, nunca ninguém me tinha ensinado que as decisões corretas se tomam pelo sentir e não pelo pensamento; nem que decidir segundo a minha natureza, mesmo que alguma decisão representasse um ato de ousadia, era uma oportunidade para me desafiar, para aprender, para me desenvolver e para me ajudar a fazer aquilo que nasci para fazer: amar-me e ser eu mesma.
        Essa, é a dádiva desta ciência da diferenciação que é o Human Design: fornecer a cada Ser informação sobre si mesmo e não sobre nenhum outro, propiciando-lhe, se a isso estiver disposto, seguir no caminho da vida rumo ao reencontro com a sua essência.
        Mas, sendo uma dádiva, não é, de todo, um conto de fadas ou uma promessa ilusória da vida perfeita. É um convite, um estímulo e uma sugestão para seguir um conjunto de instruções, únicas e particulares, que irão dar-lhe a possibilidade de, em primeiro lugar, despertar para a consciência de si e do todo. Depois, ao aceitar experimentar viver o novo conhecimento no dia a dia, de transformar a sua vida numa jornada de experiências com oportunidades e desafios, através dos quais aprenderá a aceitar-se, a amar-se a si, aos outros e a render-se à vida. É, em última análise, uma oportunidade para reaprender a sentir.
        Termino com as palavras de Clarice Lispector: “não me mostrem o que esperam de mim porque vou seguir o meu coração, não me façam ser o que não sou. Não me convidem a ser igual porque sinceramente sou diferente”
        <br></br>
        <br></br>
        Abraço sentido,
        <br></br>
        Isaura Carvalho
      </p>
      <br></br>
      <Link to="/" id="blueBtn" className="btn btn-primary" style={
        {
          margin: 100,


        }
      }
      >Voltar</Link>
       {/* <Link to="/" id="homeBtn" className="btn btn-primary" style={
        {
          margin: 100,


        }
      }
      >Home</Link> */}
      <br></br>

    <FooterB />
    </div>

  )
}

export default About;
