import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logonovo from '../assets/img/logonovo.jpg'
import { useState, React, useEffect } from 'react';
import '../assets/img/css/navbar.css';


export function NavBar3() {
  const [hState, sethState] = useState("top");

  useEffect(() => {
    var lastVal = 0;
    window.onscroll = function () {
      let y = window.scrollY;
      if (y > lastVal) {
        sethState("down");
      }
      if (y < lastVal) {
        sethState("up");
      }
      if (y === 0) {
        sethState("top");
      }
      lastVal = y;
    };
  }, []);

  return (
    <div data-testid="navBar2" className={"navbar2 text-center fixed w-full text-lg font-semibold " + hState + (hState === "down" ? " hidden" : "")} id="navbar2" >
      <Navbar id="navbar2" collapseOnSelect expand="lg" bg="white"   >
        <Container id="namecontainer">
          <Navbar.Brand id="name" href="#home"><img src={logonovo} alt="logo" id="logoMini2"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <div id="divcollapse">
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="lg-auto" id="linksDiv">
                <Nav.Link id="nav-links" href="/">Home</Nav.Link>
                <Nav.Link id="nav-links" href="about">Sobre</Nav.Link>
                <Nav.Link id="nav-links" href="human design">Human Design</Nav.Link>
                <Nav.Link id="nav-links" href="contact">Contactos</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}
