/* eslint-disable react/style-prop-object */
// import React from 'react';
// import banner from '../assets/img/banner_pic.jpg';


// export function PictureBanner() {
//   return (
//     <div className="container">
//       <div className="div_img_banner">
//           <img className="banner_card_link" src={banner} alt="banner_pic" />
//       </div>
//     </div>
//   );
// }
// import Carousel from 'react-bootstrap/Carousel';
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
// import banner_pic from '../assets/img/pic3.jpg';
// import banner_pic2 from '../assets/img/pic6.jpg';
// import banner_pic3 from '../assets/img/pic8.jpg';


export function PictureBanner() {
  return (
    // <div className="carousel">
    //   <Carousel variant="dark">
    //     <Carousel.Item>
    //         <img
    //           id="banner-picture"
    //           className="d-block w-100 "
    //           src={banner_pic}
    //           alt="First slide"
    //           height={300}

    //         />
    //       <Carousel.Caption>
    //         <p>Acredito que os potenciais humanos são ilimitados e que a vida é a oportunidade para os conhecer, desenvolver e expressar de modo consciente</p>
    //       </Carousel.Caption>
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img
    //         id="banner-picture"
    //         className="d-block w-100 "
    //         src={banner_pic2}
    //         alt="Second slide"
    //         height={300}
    //       />
    //       <Carousel.Caption>

    //       <p>Quando descobres o teu ser único, inicias um caminho onde o despertar dos teus talentos transforma a tua vida numa viagem surpreendente. </p>
    //       </Carousel.Caption>
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img
    //         id="banner-picture"
    //         className="d-block w-100 "
    //         src={banner_pic3}
    //         alt="Third slide"
    //         height={300}
    //       />
    //       <Carousel.Caption>
    //         <p>Redescobre-te. Realinha-te. Ousa ser quem és. E a vida não te resistirá. </p>
    //       </Carousel.Caption>
    //     </Carousel.Item>
    //   </Carousel>
    // </div>
    <div className="banner" >
      <div className="container" id="animation">
       <h1><strong>Quando descobres o teu ser único, <br></br> despertas para os teus talentos <br></br> e transformas a tua vida numa viagem surpreendente</strong>!
          {/* <span>Quando &nbsp;</span>
          <span>descobres &nbsp;</span>
          <span>o &nbsp;</span>
          <span>teu &nbsp;</span>
          <span>ser &nbsp;</span>
          <span>único, &nbsp;</span>
        <div>
          <span>despertas &nbsp;</span>
          <span>para &nbsp;</span>
          <span>os &nbsp;</span>
          <span>teus &nbsp;</span>
          <span>talentos &nbsp;</span>
        </div>
          <span>e &nbsp;</span>
          <span>transformas &nbsp;</span>
          <span>a &nbsp;</span>
          <span>tua &nbsp;</span>
          <span>vida &nbsp;</span>
          <span>numa &nbsp;</span>
          <span>viagem &nbsp;</span>
          <span>surpreendente &nbsp;</span> */}
        </h1>
        {/* <p>Ra Uru Hu</p> */}
        <a className="btn btn-flat" id="pullBtn" href="#about2">Descobrir</a>
      </div>
    </div>

  );
}
//sdadas
