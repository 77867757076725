import React, { Fragment, useState } from 'react'
import profile_pic1 from "../assets/img/profile_pic.jpg";
import profile_pic2 from "../assets/img/publicaçoes.jpg";
import profile_pic3 from "../assets/img/certificaçaopic.jpg";
import certificado from '../assets/img/certificado.png'
import "../assets/img/css/about.css"

export const About = () => {
  const [toggleTab, setToggleTab] = useState(1)
  const toggleState = (index) => {
    setToggleTab(index)
  }

  const profile_pics = [profile_pic1, profile_pic2, profile_pic3];
  const profile_pic = profile_pics[toggleTab - 1];

  return (
    <Fragment>

      <section className="about" id="about">

        <div className="row">

          {/* Usar a mesma função toggle para ativar diferentes imagens //  */}
          <div className="column" id="divImgabout">
            <div className="about-img">
              <img className="about-img" src={profile_pic} alt="img" />
            </div>
          </div>

          <div className="column">

            <div className="tabs" id="tabs1">

              <div className={toggleTab === 1 ? "single-tab active-tab" : "single-tab"}
                onClick={() => toggleState(1)}
              >
                <h2>Sobre</h2>
              </div>

              {/* <div className={toggleTab === 2 ? "single-tab active-tab" : "single-tab"}
                onClick={() => toggleState(2)}
              >
                <h2>Publicações</h2>
              </div> */}

              <div className={toggleTab === 3 ? "single-tab active-tab" : "single-tab"}
                onClick={() => toggleState(3)}
              >
                <h2>Certificação</h2>
              </div>

            </div>

            <div className="tab-content">

              {/* About Content */}

              <div className={toggleTab === 1 ? "content active-content" : "content"}>
                <h2>Sobre mim</h2>
                <p>Sou apaixonada pela natureza humana, pela vida com sentido, por aprender e por partilhar criativamente a sabedoria adquirida.
                  Ao longo de 30 anos como profissional de saúde, dediquei a minha vida a cuidar de pessoas.
                  Viajei por áreas apaixonantes, cujos conhecimentos vivenciei, com os quais cresci e guiei pessoas ao longo do caminho.
                  No início de 2015 o Human Design convidou-me. Senti-me conquistada de imediato pela magia desta ciência e desta arte, através da qual me fui descobrindo e encontrando respostas de como viver a minha autenticidade. Integrar esta aprendizagem na vivência quotidiana trouxe um novo e transformador sentido à minha jornada.
                  A recuperação do encanto e do amor por mim própria, por ti, pelos outros e pela vida e a perceção das infinitas possibilidades que este conhecimento abre, inspiraram-me a abraçar o projeto de me tornar Analista de Human Design.
                  No meu quotidiano profissional e em cada testemunho que recebo e guardo, reafirmo o meu alinhamento com aquilo que nasci para fazer.
                  Como projetora, vivo o meu sucesso na experiência de cada dia.
                </p>

              </div>

              {/* Skills Content */}

              {/* <div className={toggleTab === 2 ? "content active-content" : "content"}>
                <h4>Iguais, Diferentes ou Únicos?</h4>
                <p>Nunca o mundo foi tão abundante em fórmulas de tudo e para todos. Receitas que ensinam passo a passo como ser milionário, como ser feliz, como se comportar, como se alimentar, como ter sucesso, enfim! Um sem-fim de “como” se deve viver, ser ou fazer.
                  Há uns anos atrás, eu estaria certa do sucesso universal dessas fórmulas. Hoje sei que a resposta é não ou, pelo menos, não sempre, não para todos.
                  E agora algumas reflexões: servirão as mesmas fórmulas para a toda a gente? Todas as pessoas se identificarão com elas?
                  Enquanto sociedade, temos vindo a assumir implicitamente duas condições: a primeira, é que os métodos “normalizados” de como fazer isto ou aquilo são universalmente válidos e a segunda, que todos funcionamos do mesmo modo.
                  A comprovar esse facto temos a educação homogeneizada, que é o método que ainda predomina na maioria da humanidade, baseada em padrões e fórmulas com as quais aprendemos a enquadrar-nos em modelos convencionais que nos afastam progressivamente da nossa natureza, criando a maior de todas as desigualdades. Como nos ensina Aristóteles, a pior forma de desigualdade é tentar tornar iguais duas coisas diferentes.
                  E se, durante o caminho, alguém ousa ser e fazer diferente, a falta de referências para sustentar a sua decisão, a falta de sinalização de para onde seguir caminho, a pressão e o estigma social condicionam, em muitos casos, a pessoa a recuar e voltar ao chamado “normal”.
                  Depois, quando chegamos à idade adulta, sentimo-nos frequentemente perdidos, sem saber onde ou a quem recorrer, acomodando-nos por vezes a pessoas ou situações conhecidas, por medo de sair da suposta zona de conforto e de enfrentar o desconhecido, sem sabermos que com isso, podemos perder oportunidades de experienciar algo que poderia ajudar a mudar-nos ou a mudar as nossas vidas.
                  E isso leva-me a mais uma questão: afinal somos iguais ou diferentes?
                  Se pensarmos numa perspetiva abrangente, somos ambas as coisas. Senão vejamos: A nossa “igualdade” passa por exemplo pela biologia, que nos agrupa na mesma espécie; pela ética, que nos atribui os mesmos direitos e deveres na sociedade; ou pela cosmologia, que nos mostra que somos regidos pelas mesmas leis do universo. Quanto à nossa “diferença”, essa é habitualmente conotada com questões de ordem racial, cultural ou religiosa.
                  Muito do que nos iguala ou diferencia e que tem vindo a ser discutido ao longo dos anos, tem sido particularmente direcionado para a área dos direitos humanos. A este propósito, lembro-me de uma frase que se tornou num dos slogans mais usados por tintas, telas e vozes: “todos diferentes, todos iguais”. Mas o inquestionável valor dos movimentos gerados a partir desse conceito revolucionário, foi construído tendo por base os seres humanos enquanto espécie, povo, nação ou cultura.
                  No entanto, aquilo a que me refiro hoje é algo substancialmente diferente. É sobre particularidades e subtilezas, aquilo que em cada ser lhe dá a sua individualidade, independentemente da raça, cultura ou religião a que pertença. É sobre o que é ser verdadeiramente único.
                  Se olharmos à nossa volta, observamos o quão diferentes somos. Rostos, corpos, e movimentos singulares tornam cada Ser inigualável e irrepetível.
                  E se aprofundarmos um pouco, percebemos que essa originalidade já é cientificamente comprovada. Os cientistas afirmam que apenas cerca de 0.5% do DNA é único em cada ser humano. No entanto, essa diferença, aparentemente insignificante, é suficiente para que cada código genético defina características particulares como a impressão digital, a forma do movimento, o tom de voz, o olfato, entre muitas outras.
                  Concordamos então que genéticas únicas correspondem a diferentes potenciais a desenvolver e, por consequência, modos particulares de o fazer, tendo em conta a mecânica e o modo diferenciado como cada pessoa funciona.
                  Mas o mais interessante é que tudo isto se passa a um nível subtil. É uma energia que sentimos, mas que não consciencializamos. E por isso, não raramente, lhe fugimos, a ignoramos ou a expressamos desadequadamente, simplesmente porque não sabemos como fazer de outro modo.
                  Até conhecer o Human Design, nunca ninguém me tinha ensinado que as decisões corretas se tomam pelo sentir e não pelo pensamento; nem que decidir segundo a minha natureza, mesmo que alguma decisão representasse um ato de ousadia, era uma oportunidade para me desafiar, para aprender, para me desenvolver e para me ajudar a fazer aquilo que nasci para fazer: amar-me e ser eu mesma.
                  Essa, é a dádiva desta ciência da diferenciação que é o Human Design: fornecer a cada Ser informação sobre si mesmo e não sobre nenhum outro, propiciando-lhe, se a isso estiver disposto, seguir no caminho da vida rumo ao reencontro com a sua essência.
                  Mas, sendo uma dádiva, não é, de todo, um conto de fadas ou uma promessa ilusória da vida perfeita. É um convite, um estímulo e uma sugestão para seguir um conjunto de instruções, únicas e particulares, que irão dar-lhe a possibilidade de, em primeiro lugar, despertar para a consciência de si e do todo. Depois, ao aceitar experimentar viver o novo conhecimento no dia a dia, de transformar a sua vida numa jornada de experiências com oportunidades e desafios, através dos quais aprenderá a aceitar-se, a amar-se a si, aos outros e a render-se à vida. É, em última análise, uma oportunidade para reaprender a sentir.
                  Termino com as palavras de Clarice Lispector: “não me mostrem o que esperam de mim porque vou seguir o meu coração, não me façam ser o que não sou. Não me convidem a ser igual porque sinceramente sou diferente”
                  <br></br>
                  <br></br>
                  Abraço sentido
                  <br></br>
                  Isaura Carvalho
                </p> */}

                <div className="skills-row">
                </div>

              </div>

              {/* Experience Content */}

              <div className={toggleTab === 3 ? "content active-content" : "content"}>


                <div className="exp-column">
                  <p>Analista de Human Design formada e certificada pela PHDS<br></br>Certificada pela IHDS </p>
                </div>

                <div className="exp-column">
                  <p>Doutoramento em Ciências de Enfermagem na área da Hipotermia e do Conforto</p>
                </div>

                <div className="exp-column">
                  <p>Mestrado em Ciências de Enfermagem, área da Ética</p>
                </div>

                <div className="exp-column">
                  <p>Pós Graduação em Gestão e Enfermagem Perioperatória</p>
                </div>

                <div className="exp-column">
                  <p>Licenciatura em Enfermagem</p>
                </div>
                <div className="exp-column">
                  <p>Facilitadora Titulada de Biodanza</p>
                </div>
                <div className="exp-column">
                  <p>Integrative Food Consultant</p>
                </div>
                <img className="certImg" src={certificado} alt="cert" />
              </div>

            </div>

          </div>

        {/* </div> */}

      </section>

    </Fragment>
  )
}
