/* eslint-disable react/style-prop-object */
import React from 'react';


export function MiddleBanner() {

  return(
    <div className="middleBanner">
    <div className="container">
      <h1><strong>"To be correct is to appreciate your own magic, your own beauty. So, I continue to remind you: love yourself."</strong></h1>
      <p>...Ra Uru Hu</p>
    </div>
  </div>

  )
}
