import React from 'react';
import { Link } from "react-router-dom";
import picture1 from '../assets/img/pic5.jpg'
// import picture2 from '../assets/img/pic2.jpg'
// import picture3 from '../assets/img/pic8.jpg'



export function CardArticles() {

  return (
    <>
      <div className="container">
      <h2 className="text-left text-black my-5" id="cards">Artigos&nbsp;&nbsp;&nbsp; <hr id="line" /></h2>
        <div className="row">
          <div className="removed col configuration" id="artigos">
            <div className="card mb-5 mr-ml-0" id="cardartigo1">
              <img src={picture1} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Artigo nº1</h5>
                <p className="card-text">Iguais, Diferentes ou Únicos?</p>
                <Link to="card1" className="btn btn-primary" id="btnCard1">Ler mais</Link>
              </div>
            </div>
          </div>
        </div>

          {/* <div className="removed col configuration" id="artigos">
            <div className="card mb-5 mr-ml-0" id="cardartigo1">
              <img src={picture1} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Artigo nº1</h5>
                <p className="card-text">Iguais, Diferentes ou Únicos?</p>
                <Link to="card1" className="btn btn-primary" id="btnCard1">Ler mais</Link>
              </div>
            </div>
          </div> */}

      </div>
    </>
  )
}
