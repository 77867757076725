import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { About } from './components/About';
import { About2 } from './components/About2';
import Artigo1  from './pages/Artigo1';
import ContactUs from './components/ContactUs';
import { Page404 } from './components/Page404';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { CardArticles } from './components/CardArticles';

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "human design",
      element: <About2 />,
    },
    {
      path: "contact",
      element: <ContactUs />,
    },
    {
      path: "cards",
      element: <CardArticles />,
    },
    {
      path: "card1",
      element: <Artigo1 />,
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
