import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { contactConfig } from "./Content_option";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
// import { SocialIcon } from 'react-social-icons';
import { ImLink } from "react-icons/im";
import { FiLinkedin } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";



export function ContactUs() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p78mfpd', 'template_uyybhyb', form.current, 'i8akWTOgKlsQBfnMJ')
      .then((result) => {
        console.log(result.text);
        console.log("message sent")
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="contact-div" >
      {/* <Row className="mb-5 mt-3">
            <h1 className="display-4 mb-4">Contact Me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Row> */}
      <Container id="contact" >
        <div className="left-side-contact">
        </div>
        {/* <Row className="sec_sp"> */}
        <div lg="5" className="mb-5" id="smaller-div">
          <h3 className="color_sec py-4"><strong>Vamos entrar em contacto?</strong></h3>
          <address>
            <strong>Email:</strong>{" "}
            <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
              {contactConfig.YOUR_EMAIL}
            </a>
            <br />
            <br />
            {contactConfig.hasOwnProperty("YOUR_FONE") ? (
              <>
              <p>
                <strong>Tel-1&nbsp;:</strong> {contactConfig.YOUR_FONE} <br></br>  <p id="disclaimer">(chamada para a rede móvel nacional)</p>
                <strong>Tel-2&nbsp;:</strong> {contactConfig.YOUR_FONE2} <br></br>  <p id="disclaimer">(chamada para a rede móvel internacional)</p>
              </p>
                  <ul className="nav justify-content-center border-bottom pb-3 mb-3" id="linksList">
                    <li className="nav-item">
                      <a href="https://www.linkedin.com/in/isaura-carvalho-59568452/" ><FiLinkedin /></a>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.instagram.com/isaura__carvalho/?next=%2F" ><FaInstagram /></a>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.phds.pt/" ><FaHandHoldingHeart /></a>
                    </li>
                    <li className="nav-item">
                      <a href="https://www.jovianarchive.com/"><ImLink /></a>
                    </li>
                  </ul>
                  </>
            ) : (
              ""
            )}
          </address>
          {/* <h6>{contactConfig.description}</h6> */}
        </div>
        {/* </Row> */}
      </Container>
      <div className="form-col">
        <StyledContactForm>
          <div className="form-div">
            <form ref={form} onSubmit={sendEmail}>
              <label><strong>Name</strong></label>
              <input type="text" name="user_name" />
              <label><strong>Email</strong></label>
              <input type="email" name="user_email" />
              <label><strong>Message</strong></label>
              <textarea name="message" />
              <input type="submit" value="Send" />
            </form>
            <br></br>
          </div>
        </StyledContactForm>
      </div>

    </div>
  );
}

export default ContactUs;

const StyledContactForm = styled.div`
  width: 400px;
  @media screen and (max-width: 400px) {
    width: 200px;
  }
  @media screen and (max-width: 800px) {
    margin: 0 auto;
    padding: 0 ;
  }
  form {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #243E28;
      color: white;
      border: none;
    }
  }
`;
