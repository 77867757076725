import React, { Fragment, useState } from 'react'
// import profile_pic from "../assets/img/profile_pic.jpg";
import 'react-bootstrap';
import "../assets/img/css/about.css";
import livroHD from '../assets/img/CONSULTAS1.png';
import mapa from '../assets/img/mapa.png';
//n/a

export const About2 = () => {
  const [toggleTab, setToggleTab] = useState(1)
  const toggleState = (index) => {
    setToggleTab(index)
  }
  return (
    <Fragment>

      <section className="about" id="about2">



        <div className="column">
          <div className="about-img">
            {/* <img className="about-img" src={profile_pic} alt="img" /> */}
          </div>
        </div>

        <div className="column">
          <div className="tabs container" id="tabs2">

            <div className={toggleTab === 1 ? "single-tab active-tab" : "single-tab"}
              onClick={() => toggleState(1)}
            >
              <h2>Human Design</h2>
            </div>

            <div className={toggleTab === 2 ? "single-tab active-tab" : "single-tab"}
              onClick={() => toggleState(2)}
            >
              <h2>Consultas</h2>
            </div>

            {/* <div className={toggleTab === 3 ? "single-tab active-tab" : "single-tab"}
              onClick={() => toggleState(3)}
            >
              <h2>Certificação</h2>
            </div> */}

          </div>

          <div className="tab-content">
            <div className="container">
              <div className="row cardHD">
                {/* <div className="col-sm-12 col-md-6">
                  <img src={livroHD} alt="" />
                </div> */}
                {/* <div className="col-sm-12 col-md-6"> */}
                {/* About Content */}

                <div className={toggleTab === 1 ? "content active-content" : "content"}>

                  <h2>Human Design</h2>
                  <div className="d-md-flex flex-row">
                    <div className="HDtextdiv">

                      <p>O Human Design, conhecido como a Ciência da Diferenciação, é um conhecimento multidisciplinar que agrega ciências como a física quântica, a bioquímica e a genética com sabedorias ancestrais.</p>
                      <p>Cada ser humano tem uma matriz genética original e um modo de funcionar totalmente diferenciado.</p>
                      <p> Ancorado na inteligência celular, este conhecimento fornece o manual de instruções para que cada Ser descubra a sua unicidade e coloque os seus potenciais em prática de forma eficaz.
                      </p>
                      <p>Com esta poderosa ferramenta, despertas para a consciência, aprendes a viver a vida sendo quem és, a tomar as decisões corretas e a dar o teu contributo ao mundo.</p>
                    </div>
                    <div className="HDimgdiv">
                      <img src={mapa} alt="" id="mapaImg" />
                    </div>
                  </div>


                </div>
              </div>

              {/* Skills Content */}

              <div className={toggleTab === 2 ? "content active-content" : "content"}>
                <div className="d-md-flex flex-row">
                  <div className="ConsultasImg">
                    <img src={livroHD} alt="" id="livroImg" />
                  </div>
                  <div className="consultasText">
                    <h2>Consultas</h2>
                    <h6 id="subtitle">Primeira Análise ou Foundation Reading</h6>
                    <p>Este é primeiro contacto, dirigido a quem quer entrar na viagem da autodescoberta e aprender a viver de acordo o seu tipo, a sua estratégia de vida e a sua autoridade interna de decisão.
                      Estas são as bases para tomar as decisões corretas, para aceder aos próprios potenciais únicos e para se alinhar com a própria essência, porque esse é o propósito.
                    </p>
                    <h6>Consultas de Aprofundamento</h6>
                    <p>Esta consulta é dirigida a quem já entrou nesta viagem, já integrou na sua vida o conhecimento de base e pretende evoluir. </p>
                    <p>Individual - Onde são aprofundados aspetos relevantes do próprio Ser.</p>
                    <p>Relação - Onde se aprende a compreender e a tirar o melhor partido das interações com o outro, por exemplo casais, pais, filhos, irmãos, amigos, entre outras possibilidades. </p>
                    <a href="#contact"><button type="button" className="btn btn-outline-success" id="btnConsultas" >Marcar</button></a>
                  </div>
                </div>
                <div className="skills-row">

                  {/* <div className="skills-column">
        <div className="progress-wrap">
          <h3>Developer</h3>
          <div className="progress">
            <div className="progress-bar">
              <span>80%</span>
            </div>
          </div>
        </div>
       </div>

       <div className="skills-column">
        <div className="progress-wrap">
          <h3>Designer</h3>
          <div className="progress">
            <div className="progress-bar Designer">
              <span>90%</span>
            </div>
          </div>
        </div>
       </div>

       <div className="skills-column">
        <div className="progress-wrap">
          <h3>Javascript</h3>
          <div className="progress">
            <div className="progress-bar Javascript">
              <span>85%</span>
            </div>
          </div>
        </div>
       </div>

       <div className="skills-column">
        <div className="progress-wrap">
          <h3>PhotoShop</h3>
          <div className="progress">
            <div className="progress-bar PhotoShop">
              <span>88%</span>
            </div>
          </div>
        </div>
       </div> */}

                </div>
              </div>

              {/* Experience Content */}

              {/* <div className={toggleTab === 3 ? "content active-content" : "content"}>

                <div className="exp-column">
                  <h3>Human Design</h3>
                  <span>9999-9999</span>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim error rem dicta perferendis et qui obcaecati labore accusantium cupiditate libero.</p>
                </div>

                <div className="exp-column">
                  <h3>PhD Nursing Sciences</h3>
                  <span>9999-9999</span>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim error rem dicta perferendis et qui obcaecati labore accusantium cupiditate libero.</p>
                </div>

                <div className="exp-column">
                  <h3>???????</h3>
                  <span>9999-9999</span>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim error rem dicta perferendis et qui obcaecati labore accusantium cupiditate libero.</p>
                </div>
                <td><img src={certificado} alt="" id="livroImg" />

              </div> */}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
